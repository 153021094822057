import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {showConfirm} from '@/util/confirm';
import {MESSAGES} from '@/util/message-notification';

const state = () => ({
  loading: false,
  contentStr: '',
  api: ConstantAPI.content,
  visible: false,
});

const actions = {
  async update({ commit, state }, payload) {
    try {
      if (await showConfirm(MESSAGES.MESSAGES_0015)) {
        commit('setLoading', true)
        const response = await DataService.callApi(state.api.CREATE, payload);
        checkResponse(response, () => {
          notification.success({
            message: 'Thông báo',
            description: MESSAGES.MESSAGES_0020,
            duration: 4,
          });
        });
        commit('setLoading', false)
      }
    } catch (err) {
      console.log(err);
    }
  },
  async getByType({ commit, state }, type) {
    try {
      commit('setLoading', true)
      let data = null
      const response = await DataService.callApi({
        url: state.api.GET_BY_TYPE.url + type,
        method: state.api.GET_BY_TYPE.method
      });
      checkResponse(response, () => {
        data = response.data
        state.content = response.data.content
      });
      commit('setLoading', false)
      return data
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};

export const TABLE_LINE_CHART = {
  LAND: 'LAND',
  RECRUITMENT: 'RECRUITMENT',
  CV: 'CV',
  CLASSIFIEDS: 'CLASSIFIEDS',
};

export const LABEL = {
  YEAR: 'Tháng',
  MONTH: 'Ngày',
};

export const PRE_LABEL = {
  DAY: 'Hôm qua',
  WEEK: 'Tuần trước',
  MONTH: 'Tháng trước',
  YEAR: 'Năm trước',
};

export const DAY_OF_WEEK = [
  'Chủ nhật',
  'Thứ 2',
  'Thứ 3',
  'Thứ 4',
  'Thứ 5',
  'Thứ 6',
  'Thứ 7',
];

export const REQUEST_TYPE = {
  BANK_SEARCH_ACC: 4,
  BANK_ORTHER: 5,
  TELE_SEARCH_IP: 1,
  TELE_MANAGE_IP: 2,
  TELE_ORTHER: 3,
};

export const REQUEST_STATUS = {
  SENT: 0,
  CANCEL: 1,
  SEND_BACK: 2,
  RECEIVED: 3,
  PROCESSED: 4,
};

export const RECEIVER = {
  ORGANIZATION: 1,
  NETWORK: 2,
  BANK: 3,
};

export const STATUS_NOTIFY_REQUEST = {
  REQUEST_PROCESSING: 0,
};

export const RECEIVER_OPTIONS = [
  { label: 'Nhà mạng', value: 2 },
  { label: 'Ngân hàng', value: 3 },
];

export const STATUS_ACTION = {
  INACTIVE: 0,
  ACTIVE: 1,
};

export const STATUS_ALERT = {
  1: 'Tiếp nhận',
  2: 'Xử lý',
};

export const STATUS_ALERT_LIST = [
  { label: 'Tiếp nhận', value: 1 },
  { label: 'Xử lý', value: 2 },
];

export const METHOD_ALERT = {
  email: 'Email',
  sms: 'SMS',
  toast: 'Toast',
};

export const METHOD_ALERT_LIST = [
  { label: METHOD_ALERT['email'], value: 'email' },
  { label: METHOD_ALERT['sms'], value: 'sms' },
  { label: METHOD_ALERT['toast'], value: 'toast' },
];

export const PRIORITY = [
  { label: 'Hỏa tốc', value: 1 },
  { label: 'Khẩn', value: 2 },
  { label: 'Thường', value: 3 },
];

export const BANK_ALL = 'BANK_ALL'

import {FORM_MODE} from '@/util/common-constant';
import {RECEIVER, STATUS_ACTION} from '@/config/Constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAll(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.name, displayName: e.displayName }));
    state.listBank = data
      .filter(
        (e) => e.type === RECEIVER.BANK && e.status === STATUS_ACTION.ACTIVE
      )
      .map((e) => {
        state.lstKeyValueBankCode[e.bankCode] = e.name
        return { value: e.id, label: e.name, bankCode: e.bankCode }
      });
    // state.listBank.unshift({ value: BANK_ALL, label: 'Tất cả' })
    state.listNetwork = data
      .filter(
        (e) => e.type === RECEIVER.NETWORK && e.status === STATUS_ACTION.ACTIVE
      )
      .map((e) => ({ value: e.id, label: e.name }));
    state.loading = false;
  },
  preCreate(state = null) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    // state.agency = {
    //   name: '',
    //   type: 1,
    //   description: '',
    //   status: 1,
    //   hasDataset: null,
    //   hasDatasetSql: null,
    //   internalShare: null,
    //   share: null,
    //   ngsp: null,
    //   lgsp: null,
    //   regulations: null,
    // };
    state.agency = {
      bankCode: '',
      description: '',
      displayName: '',
      id: null,
      name: '',
      status: 1,
      type: 1
    };
  },
  setFormValue(state, { agency, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.agency = {
      ...agency,
    };
  },
};

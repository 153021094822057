import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import mutations from './mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
  loading: false,
});

const actions = {
  async exportExcelCaseList({  commit }, param) {
    try {
      commit('setLoading', true);
      notification.info({
        message: 'Thông báo',
        description: 'Đang tiến hành tải',
        duration: 4,
      });
      const response = await DataService.getConfig(
          ConstantAPI.reportStatistics.caseList.EXPORT_EXCEL.url,
          param,
          null,
          'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `bao-cao-vu-viec-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: MESSAGES.MESSAGES_0023,
        duration: 4,
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async exportExcelJunkAccount({ commit }, param) {
    try {
      commit('setLoading', true);
      notification.info({
        message: 'Thông báo',
        description: 'Đang tiến hành tải',
        duration: 4,
      });
      const response = await DataService.getConfig(
          ConstantAPI.reportStatistics.junkAccounts.EXPORT_EXCEL.url,
          param,
          null,
          'blob'
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `bao-cao-tai-khoan-rac-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      notification.success({
        message: 'Thông báo',
        description: MESSAGES.MESSAGES_0023,
        duration: 4,
      });
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};

import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import mutations from '@/vuex/modules/request/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {MESSAGES} from '@/util/message-notification';

const state = () => ({
    loading: false,
    api: ConstantAPI.report,
    detail: null,
});

const actions = {
    async createReport({commit, state}, data) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(state.api.CREATE_REPORT, data);
            checkResponse(response, () => {
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGE_005,
                    duration: 4,
                });
            });
            commit('setLoading', false);
        } catch (err) {
            console.log(err);
        }
    },
    async getReportById({commit, state}, id) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi({
                url: state.api.GET_BY_ID.url + id,
                method: state.api.GET_BY_ID.method
            });
            checkResponse(response, () => {
                state.detail = response.data
            });
            commit('setLoading', false);
        } catch (err) {
            console.log(err);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};

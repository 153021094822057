<template>
  <template v-if="readOnly">
    {{ formatDateVN(value) }}
  </template>
  <DatePickerWrapper v-else>
    <a-date-picker
      v-model:value="valueDate"
      @change="change"
      :disabled-date="disabledDate"
      :placeholder="placeholder"
      :format="format"
      :show-time="showTime"
      class="w-100 custom-date-picker"
      size="small"
    >
    </a-date-picker>
  </DatePickerWrapper>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue';
import { DatePickerWrapper } from './style';
import VueTypes from 'vue-types';
import dayjs from 'dayjs';
import { formatDate } from '@/util/common-utils';

export default defineComponent({
  name: 'DatePicker',
  components: {
    DatePickerWrapper,
  },
  props: {
    value: VueTypes.oneOfType([
      VueTypes.string,
      VueTypes.oneOfType([VueTypes.string]),
    ]),
    format: VueTypes.string.def('DD/MM/YYYY'),
    disabledDate: VueTypes.func,
    placeholder: VueTypes.string,
    showTime: VueTypes.oneOfType([VueTypes.bool.def(false), VueTypes.object]),
    readOnly: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const valueDate = ref(props.value ? dayjs(props.value) : null);
    const change = (val) => emit('update:value', val ? val.toDate() : null);
    watch(
      () => props.value,
      (val) => (valueDate.value = val ? dayjs(val) : null)
    );
    const formatDateVN = computed(
      () => (date) => formatDate(date, props.format)
    );
    return {
      valueDate,
      change,
      formatDateVN,
    };
  },
});
</script>

<style lang="scss">
.ant-picker-ok {
  .ant-btn-primary {
    background-color: #b4272d !important;
  }
}
</style>

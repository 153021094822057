export const FORM_MODE = {
  CREATE: 1,
  UPDATE: 2,
  VIEW: 3,
};

export const GENDERS = [
  {
    value: 1,
    label: 'Nam',
  },
  {
    value: 0,
    label: 'Nữ',
  },
  {
    value: 2,
    label: 'Khác',
  },
];

export const STATUS = [
  {
    value: 1,
    label: 'Hoạt động',
  },
  {
    value: 0,
    label: 'Không hoạt động',
  },
];

export const STATUS_APPROVAL = [
  {
    value: 1,
    label: 'Đã duyệt',
  },
  {
    value: 0,
    label: 'Chưa duyệt',
  },
  {
    value: -1,
    label: 'Từ chối',
  },
];

export const AGENCY_TYPE = [
  {
    value: 1,
    label: 'Cơ quan',
  },
  // {
  //   value: 2,
  //   label: 'Nhà mạng',
  // },
  {
    value: 3,
    label: 'Bank',
  },
];

export const AGENCY_PROCESS = [
  {
    value: 0,
    label: 'Chưa có thông tin',
  },
  {
    value: 1,
    label: 'Chưa thực hiện',
  },
  {
    value: 2,
    label: 'Đang thực hiện',
  },
  {
    value: 3,
    label: 'Đã thực hiện',
  },
];

export const defaultButton = (menu) => {
  const buttonsForm = [
    { type: 'RESET' },
    { type: 'SEARCH' },
    { type: 'CREATE', permission: `${menu}:create` },
    { type: 'DELETE', permission: `${menu}:delete` },
  ];
  const buttonActions = [
    { type: 'EDIT', permission: `${menu}:edit` },
    { type: 'VIEW' },
  ];
  return { buttonsForm, buttonActions };
};

export const defaultButtonApproval = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'APPROVAL' }, { type: 'CANCEL_APPROVAL' }];
  return { buttonsForm, buttonActions };
};
export const defaultButtonConfigAgency = () => {
  const buttonsForm = [{ type: 'RESET' }, { type: 'SEARCH' }];
  const buttonActions = [{ type: 'SHOW' }, { type: 'HIDDEN' }];
  return { buttonsForm, buttonActions };
};
export const HIDDEN_MENUS = ['/system/agency/detail'];
export const STATUS_AGENCY = {
  show: 1,
  hidden: 0,
};
export const STATUS_AGENCY_TEXT = [
  {
    value: 1,
    label: 'Hiển thị',
  },
  {
    value: 0,
    label: 'Ẩn',
  },
];

export const CONTENT_TYPE = [
  {
    value: 1,
    label: 'Đề nghị gửi ngân hàng',
  },
  {
    value: 2,
    label: 'Thống kê vụ việc liên quan',
  },
  {
    value: 3,
    label: 'Thống kê tài khoản rác',
  },
]
export const CONTENT_TYPE_VALUE = {
  DN_NH: 1,
  TK_VV: 2,
  TK_TKR: 3
}

export const WARN_TYPE = [
  {
    value: 0,
    label: 'Trắng',
  },
  {
    value: 1,
    label: 'Đỏ',
  },
  {
    value: 2,
    label: 'Vàng',
  },
]
export const WARN_TYPE_KEY_VALUE = {
  WHITE: 0,
  RED: 1,
  YELLOW: 2
}


export const LIST_RESULT = [
  {value: 'Phong toả', label: 'Phong toả'},
  {value: 'Trì hoãn', label: 'Trì hoãn'},
  {value: 'Giao dịch bình thường', label: 'Giao dịch bình thường'}
]

export const LIST_LOAI_BAO_CAO = [
  {value: 1, label: 'Thống kê vụ việc, vụ án liên quan đến tài khoản ngân hàng rác'},
  {value: 2, label: 'Thống kê tài khoản ngân hàng rác'}
]
export const LOAI_BAO_CAO_VALUE = {
  VU_VIEC: 1,
  TK_RAC: 2
}



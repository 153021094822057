import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    state.listAll = data.map((e) => ({ value: e.id, label: e.title }));
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.dataset = {
      id: null,
      datasetFields: [],
      title: '',
      status: 1,
      order: '',
    };
  },
  setFormValue(state, { dataset, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.dataset = {
      ...dataset,
      files: dataset.files.map((e) => e.url),
      datasetFields: dataset.datasetFields.map((e) => e.fieldId),
    };
  },
  getReportByAgency(state, data) {
    state.dataset = {
      name: data.map((e) => e.name),
      value: data.map((e) => e.value),
    };
  },
  setManageData(state, data) {
    state.manageData = data;
  },
  setProvideData(state, data) {
    state.provideData = data;
  },
  setFieldData(state, data) {
    state.fieldData = data;
  },
  setTotalFile(state, data) {
    state.totalFile = data;
  },
  setTotalFileUpload(state, data) {
    state.totalFileUpload = data;
  },
  setTotalFileDownload(state, data) {
    state.totalFileDownload = data;
  },
  setTotalAgenciesUploaded(state, data) {
    state.totalAgenciesUploaded = data;
  },
  setTopFileUpload(state, data) {
    state.topFileUpload = data;
  },
  setTopAgencies(state, data) {
    state.topAgencies = data;
  },
  setFileExtensions(state, data) {
    state.fileExtensions = data;
  },
  setDomain(state, data) {
    state.domainData = data;
  },
  setOrg(state, data) {
    state.orgData = data;
  },
  setStatusData(state, data) {
    state.statusData = data;
  },
  setSuccessData(state, data) {
    state.successData = data;
  },
  setSummaryData(state, data) {
    state.summaryData = data;
  },
};

<template>
  <div class="align-center">
    <a-tooltip title="Xem" v-if="hasView">
      <a-button
        type="ghost"
        shape="circle"
        @click="emitEvent('onView')"
        class="text-white bg-[#C5A136] mr-5 hover:bg-[#C5A136] focus:bg-[#C5A136]"
      >
        <template #icon>
          <cds-feather-icons type="eye" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Sửa" v-if="hasEdit">
      <a-button
        type="primary"
        :disabled="disabledEdit"
        shape="circle"
        class="text-white bg-kns-main-dark hover:bg-kns-main-dark"
        @click="emitEvent('onEdit')"
      >
        <template #icon>
          <cds-feather-icons type="edit-3" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Duyệt" v-if="hasApproval && !isShowApproval">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #20c997"
        shape="circle"
        @click="emitEvent('onApproval')"
      >
        <template #icon>
          <cds-feather-icons type="check" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Hủy" v-if="hasCancelApproval && isShowApproval">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onCancelApproval')"
      >
        <template #icon>
          <cds-feather-icons type="x" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Hiển thị" v-if="hasShow && !isShow">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #20c997"
        shape="circle"
        @click="emitEvent('onShow')"
      >
        <template #icon>
          <cds-feather-icons type="eye" />
        </template>
      </a-button>
    </a-tooltip>
    <a-tooltip title="Ẩn" v-if="hasHidden && isShow">
      <a-button
        type="ghost"
        class="mr-5"
        style="color: #ff4d4f"
        shape="circle"
        @click="emitEvent('onHidden')"
      >
        <template #icon>
          <cds-feather-icons type="eye-off" />
        </template>
      </a-button>
    </a-tooltip>

    <a-tooltip title="TOP" v-if="hasOnTop">
      <a-button
        type="ghost"
        style="color: #20c997"
        shape="circle"
        @click="emitEvent('onTop')"
      >
        <template #icon>
          <cds-feather-icons type="arrow-up" />
        </template>
      </a-button>
    </a-tooltip>
  </div>
</template>

<script>
import VueTypes from 'vue-types';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ActionButton',
  props: {
    // {type, permission}
    buttons: VueTypes.array.def([]),
    disabledEdit: VueTypes.bool.def(false),
    isShowApproval: VueTypes.bool.def(false),
    isShow: VueTypes.bool.def(false),
  },
  setup(props, { emit }) {
    const emitEvent = (event) => {
      emit(event);
    };
    const { state } = useStore();
    const permissions = computed(() => state.auth.userInfo.permissions || []);
    const hasButton = (button) =>
      props.buttons.some(
        (e) =>
          e.type === button &&
          (!e.permission || permissions.value.includes(e.permission))
      );
    return {
      emitEvent,
      hasEdit: hasButton('EDIT'),
      hasView: hasButton('VIEW'),
      hasDelete: hasButton('DELETE'),
      hasOnTop: hasButton('ON-TOP'),
      hasApproval: hasButton('APPROVAL'),
      hasCancelApproval: hasButton('CANCEL_APPROVAL'),
      hasShow: hasButton('SHOW'),
      hasHidden: hasButton('HIDDEN'),
    };
  },
});
</script>

<style scoped></style>

import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import mutations from '@/vuex/modules/request/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {checkResponse} from "@/util/common-utils";
import {notification} from "ant-design-vue";
import {showConfirm} from "@/util/confirm";
import {MESSAGES} from "@/util/message-notification";

const state = () => ({
    listBank: [],
    loading: false,
    api: ConstantAPI.shareData,
    listBankKeyValue: []
});

const actions = {
    // async getAllBankJunkAccounts({state}, params) {
    //     try {
    //         const response = await DataService.callApi(
    //             state.api.GET_ALL_BANK_JUNK_ACCOUNT,
    //             null,
    //             params,
    //             null
    //         );
    //         checkResponse(response, () => {
    //             state.listBank = response.data
    //         });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // },
    // async getAllBankCaseList({state}, params) {
    //     try {
    //         const response = await DataService.callApi(
    //             state.api.GET_ALL_BANK_CASE_LIST,
    //             null,
    //             params,
    //             null
    //         );
    //         checkResponse(response, () => {
    //             state.listBank = response.data
    //         });
    //     } catch (err) {
    //         console.log(err);
    //     }
    // },
    setLoading({state}, loading) {
        state.loading = loading
    },
    async exportJunkAccounts({commit, state}, params) {
        try {
            commit('setLoading', true);
            notification.info({
                message: 'Thông báo',
                description: 'Đang tiến hành tải file',
                duration: 4,
            });
            const response = await DataService.getConfig(
                state.api.EXPORT_DATA_JUNK_ACCOUNTS.url,
                params,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `THỐNG_KÊ_TK_CÓ_DẤU_HIỆU_VPPL_${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: 'Thành công',
                duration: 4,
            });
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async exportCaseList({commit, state}, params) {
        try {
            commit('setLoading', true);
            notification.info({
                message: 'Thông báo',
                description: 'Đang tiến hành tải file',
                duration: 4,
            });
            const response = await DataService.getConfig(
                state.api.EXPORT_DATA_CASE_LIST.url,
                params,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `THỐNG_KÊ_VỤ_VIỆC-${Date.now()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            notification.success({
                message: 'Thông báo',
                description: 'Thành công',
                duration: 4,
            });
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async updateCaseList({commit, state}, payload) {
        try {
            if (await showConfirm(MESSAGES.MESSAGES_0015)) {
                commit('setLoading', true);
                let isSuccess = false
                const response = await DataService.callApi(state.api.UPDATE_CASE_LIST, payload);
                checkResponse(response, () => {
                    isSuccess = true
                    notification.success({
                        message: 'Thông báo',
                        description: MESSAGES.MESSAGES_0020,
                        duration: 4,
                    });
                })
                return isSuccess
            }
        } catch (err) {
            console.log(err)
        } finally {
            commit('setLoading', false);
        }
    },
    async updateJunkAccount({commit, state}, payload) {
        try {
            if (await showConfirm(MESSAGES.MESSAGES_0015)) {
                commit('setLoading', true);
                let isSuccess = false
                const response = await DataService.callApi(state.api.UPDATE_JUNK_ACCOUNT, payload);
                checkResponse(response, () => {
                    isSuccess = true
                    notification.success({
                        message: 'Thông báo',
                        description: MESSAGES.MESSAGES_0020,
                        duration: 4,
                    });
                })
                return isSuccess
            }
        } catch (err) {
            console.log(err)
        } finally {
            commit('setLoading', false);
        }
    },
    async getAllBank({commit, state}, params = {type: 3}) {
        commit('setLoading', true);
        state.listBankKeyValue = []
        const response = await DataService.callApi(ConstantAPI.agency.ALL, null, params);
        state.listBank = response.data.filter(e => e.type === 3)
        state.listBank.forEach(e => {
            state.listBankKeyValue[e.bankCode] = e.name
        })
        commit('setLoading', false);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
